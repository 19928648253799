<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="mb-3">Personal Information</h2>
                      <h4 class="mb-3">Please complete your profile</h4>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">3</a>
                        </li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="profile-pic-col text-left">
                        <div class="form-group">
                          <label>Date of Birth (MM/DD/YYYY)</label>
                          <!-- <Datepicker
                            :lowerLimit="dateLowerLimit"
                            :upperLimit="dateUpperLimit"
                            inputFormat="MM-dd-yyyy"
                            class="form-control"
                            v-model="dob"
                          /> -->
                          <div class="row">
                            <div class="col-4 pl-2">
                              <label for="">Month</label>
                              <select
                                v-model="dobMonth"
                                class="form-control select"
                              >
                                <option
                                  v-for="i in months"
                                  :key="i"
                                  :value="i"
                                  >{{ i }}</option
                                >
                              </select>
                            </div>
                            <div class="col-4 pl-1">
                              <label for="">Day</label>
                              <select
                                v-model="dobDay"
                                class="form-control select"
                              >
                                <option v-for="i in days" :key="i" :value="i">{{
                                  i
                                }}</option>
                              </select>
                            </div>
                            <div class="col-4 pl-1">
                              <label for="">Year</label>
                              <select
                                v-model="dobYear"
                                class="form-control select"
                              >
                                <option v-for="year in years" :value="year">{{
                                  year
                                }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Weight - In Pounds (LB)</label>
                          <div class="row">
                            <div class="col-12 pl-2">
                              <input
                                type="number"
                                min="0"
                                max="800"
                                class="form-control"
                                name="weight"
                                v-model="weight"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Height - In Inch (IN)</label>
                          <div class="row">
                            <div class="col-12 pl-2 text-center">
                              <input
                                type="number"
                                min="0"
                                max="500"
                                v-model="height"
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <button
                              type="submit"
                              class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              "
                            >
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/patient/step-2">
                              <a
                                href="javascript:void()"
                                class="
                                  btn
                                  bg-warning-light
                                  btn-block btn-sm
                                  login-btn
                                "
                              >
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright"></div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import Datepicker from "vue3-datepicker";
import commonMixin from "../../mixins/common";

export default {
  data() {
    return {
      // dob: new Date(),
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(),
      weight: 0,
      height: 0,
      dobDay: 1,
      dobMonth: 1,
      dobYear: 1970,
    };
  },
  components: {
    Datepicker,
  },

  mixins: [commonMixin],
  computed: {
    days() {
      let daysArray = [];
      for (let i = 1; i <= 31; i++) {
        daysArray.push(i < 10 ? `0${i}` : i);
      }
      return daysArray;
    },
    months() {
      return [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
    },
  },
  mounted() {
    const splitDate = String(this.$store.getters.user.dob).split("/");
    if (splitDate && splitDate.length > 0) {
      this.dobDay = splitDate[2];
      this.dobMonth = splitDate[1];
      this.dobYear = splitDate[0];
    }

    this.weight = String(this.$store.getters.user.weight).split("-")[0];
    this.height = String(this.$store.getters.user.height).split("-")[0];
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-2");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    async saveStep() {
      try {
        this.showLoader(true);

        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          dob: `${this.dobYear}/${this.dobMonth}/${this.dobDay}`,
          weight: this.weight,
          weight_unit: "LB",
          height: this.height,
          height_unit: "IN",
          current_registration_step: 4,
        });

        this.$router.push("/patient/step-4");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },
  },
};
</script>
